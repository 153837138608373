// Primary colors
// Brand color
@jobscanBlue: #2aacea;

// Commonly used colors
@black: #222222; // Should be used to emphasize content (e.g. headings)
@white: #ffffff; // Should be used on dark backgrounds or where appropriate
@muted: #444444; // Should be used to de-emphasize content (e.g. body text)

// Secondary colors, used sparingly in illustration or site elements
// Variations of brand color, used for illustrations
@lightBlue: #51bbee;
@lighterBlue: #7eccf2;
@darkBlue: #099ce3;
@darkerBlue: #0479b2;

// Match rate colors
@matchGreen: #86da3e;
@matchYellow: #ffcc00;
@matchOrange: #ff9900;

// Toast background colors
@highlightYellow: #f6f3dd;
@successGreen: #e6f7e7;
@infoBlue: #f0f8ff;

// Danger colors
@red: #ff6565;
@lightRed: #fff5f5;

// Grays
@darkerGray: #4a4a4a;
@darkGray: #777777;
@gray: #b0b0b0;
@lightGray: #e5e5e5;
@lighterGray: #f6f6f6;

// Other brand colors, use as needed
@linkedinBlue: #0077b5;
@facebookBlue: #1877f2;
@youtubeRed: #ff0000;
@twitterBlue: #1da1f2;

//Widelab Colors
@bgBlue: #F5F8FA;
@darkSlate: #1F344C;
@slate: #7D89A3;

@jobscanBlueGradient: linear-gradient(45deg, rgba(23,155,217,0.80) 0%, rgba(42,172,234,0.80) 100%);

// Classes to represent use of color, mostly to add to text based tags
.interactive {
	color: @jobscanBlue;
}

.white {
	color: @white;
}

.black {
	color: @black;
}

.muted {
	color: @muted;
}

.loading-ellipsis:after {
	content: '\00a0\00a0\00a0';
	animation: progress-ellipsis 1s infinite;
}

@keyframes progress-ellipsis {
	0% {
		content: '\00a0\00a0\00a0';
	}
	30% {
		content: '.\00a0\00a0';
	}
	60% {
		content: '..\00a0';
	}
	90% {
		content: '...';
	}
}

/*  Breakpoints  */
/* 
    Mobile (<= 576px)
    Tablet (577px-992px)
    Desktop (> 992px)
*/
