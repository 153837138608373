.interactive {
  color: #2aacea;
}
.white {
  color: #ffffff;
}
.black {
  color: #222222;
}
.muted {
  color: #444444;
}
.loading-ellipsis:after {
  content: '\00a0\00a0\00a0';
  animation: progress-ellipsis 1s infinite;
}
@keyframes progress-ellipsis {
  0% {
    content: '\00a0\00a0\00a0';
  }
  30% {
    content: '.\00a0\00a0';
  }
  60% {
    content: '..\00a0';
  }
  90% {
    content: '...';
  }
}
/*  Breakpoints  */
/* 
    Mobile (<= 576px)
    Tablet (577px-992px)
    Desktop (> 992px)
*/
body,
label,
.checkbox label {
  font-weight: 300;
}
/* Helpers */
.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}
.linkedin-logo {
  color: #0077b5;
}
/**=========================default2.blade.php================================**/
body {
  -webkit-overflow-scrolling: touch;
  height: 100%;
}
.alert.alert-pastel-danger,
.alert.alert-pastel-warning,
.alert.alert-pastel-info,
.alert.alert-pastel-success {
  background-color: #ffffee;
  border-width: 0px;
  border-radius: 0px;
  box-shadow: 0px 0px 5px rgba(51, 51, 51, 0.3);
  letter-spacing: 1px;
}
.alert.alert-pastel-info {
  background-color: #d9edf7;
  color: #31708f;
}
.alert.alert-pastel-success {
  background-color: #dff0d8;
  color: #3c763d;
}
.alert.alert-pastel-warning {
  background-color: #fcf8e3;
  color: #8a6d3b;
}
.alert.alert-pastel-danger {
  background-color: #f2dede;
  color: #a94442;
}
.alert.alert-pastel-danger > [data-notify="title"],
.alert.alert-pastel-warning > [data-notify="title"],
.alert.alert-pastel-info > [data-notify="title"] {
  display: block;
  font-weight: 700;
  margin-bottom: 5px;
}
[data-notify="container"][class*="alert-pastel-"] > [data-notify="message"] {
  font-weight: 400;
}
.alert-info-a {
  background-color: aliceblue;
  color: #1b6f96;
}
.alert-warning-a {
  background-color: #fff2dd;
  color: #bf7500;
}
.alert-danger-a {
  background-color: #f7e1e5;
  color: darkred;
}
.alert-success-a {
  background-color: #e6f7e7;
  color: darkgreen;
}
.alert-success-a a,
.alert-danger-a a,
.alert-warning-a a,
.alert-info-a a {
  color: #2aacea;
}
#promo-toaster {
  display: none;
}
@media (max-width: 799px) {
  .purechat.purechat-widget {
    display: none !important;
  }
}
/**===========================default2.blade.php==============================**/
/**====================usernav.blade.php=============================**/
ul li.dropdown-list-notif {
  display: none;
  padding: 15px 15px 10px;
  text-transform: none;
  border-bottom: 1px solid #e5e5e5;
  background-color: #e9f6fc;
  color: #4a4a4a;
}
ul li.dropdown-list-notif.read {
  background-color: #FFFFFF;
}
ul li.dropdown-header {
  background-color: #2aacea;
  color: white;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 2px;
}
#notificationList {
  min-width: 250px;
}
ul .dropdown-menu.new-styled-dropdown {
  margin-top: 30px !important;
  padding: 0 !important;
  border: none;
}
ul .dropdown-menu.new-styled-dropdown > li > a {
  padding: 5px 15px;
}
ul#notificationList.dropdown-menu.new-styled-dropdown > li > a {
  padding: 0;
  display: inline-block;
  color: #2aacea;
  font-size: 100%;
}
ul .dropdown-menu.new-styled-dropdown:before {
  content: '';
  width: 0;
  height: 0;
  border-bottom: 11px solid #2aacea;
  border-right: 11px solid transparent;
  border-left: 11px solid transparent;
  right: 10px;
  top: -11px;
  position: absolute;
  display: block;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.date-time-stamp {
  font-style: italic;
  font-size: 12px;
  padding-left: 15px;
  margin-top: -8px;
  margin-right: -5px;
}
.badge-notifications.unread {
  background-color: #ff9900;
}
#user-secondary-nav a {
  color: white;
}
#user-secondary-nav > li.active > a,
#user-secondary-nav > li.active > a:focus,
#user-secondary-nav > li.active > a:hover {
  background-color: inherit;
  pointer-events: none;
}
#user-secondary-nav > li.active > a span {
  opacity: 0.6;
}
#user-secondary-nav > li.active > a.emphasis-flow {
  border: 1px solid white;
}
#user-secondary-nav > li > a,
#user-secondary-nav > li > a:focus,
#user-secondary-nav > li > a:hover {
  background-color: inherit;
}
#register-cta {
  color: white;
}
#register-cta span {
  opacity: 0.8;
  color: white;
}
#user-secondary-nav {
  background-color: inherit;
  margin-bottom: 0;
  padding-bottom: 0;
}
#user-secondary-nav ul.dropdown-menu li a {
  font-size: 80%;
}
@media (min-width: 768px) {
  #user-secondary-nav > li.active > a.nav-list-item:after,
  #user-secondary-nav > li > a.nav-list-item:hover:after {
    opacity: 1;
  }
  #user-secondary-nav > li > a.nav-list-item:after {
    opacity: 0;
    content: '';
    width: 0;
    height: 0;
    border-bottom: 11px solid white;
    border-right: 11px solid transparent;
    border-left: 11px solid transparent;
    position: relative;
    top: 17px;
    margin: auto;
    display: block;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }
  #user-secondary-nav ul.dropdown-menu li a {
    color: black;
  }
}
/**====================usernav.blade.php=============================**/
/**====================usernav-premiumplus.blade.php=============================**/
#user-secondary-nav a {
  color: white;
}
#user-secondary-nav > li.active > a,
#user-secondary-nav > li.active > a:focus,
#user-secondary-nav > li.active > a:hover {
  background-color: inherit;
  pointer-events: none;
}
#user-secondary-nav > li.active > a span {
  opacity: 0.6;
}
#user-secondary-nav > li.active > a.emphasis-flow {
  border: 1px solid white;
}
#user-secondary-nav > li > a,
#user-secondary-nav > li > a:focus,
#user-secondary-nav > li > a:hover {
  background-color: inherit;
}
#register-cta {
  color: white;
}
#register-cta span {
  opacity: 0.8;
  color: white;
}
#user-secondary-nav {
  background-color: inherit;
  margin-bottom: 0;
  padding-bottom: 0;
}
#user-secondary-nav ul.dropdown-menu li a {
  font-size: 80%;
}
@media (min-width: 768px) {
  #user-secondary-nav > li.active > a.nav-list-item:after,
  #user-secondary-nav > li > a.nav-list-item:hover:after {
    opacity: 1;
  }
  #user-secondary-nav > li > a.nav-list-item:after {
    opacity: 0;
    content: '';
    width: 0;
    height: 0;
    border-bottom: 11px solid white;
    border-right: 11px solid transparent;
    border-left: 11px solid transparent;
    position: relative;
    top: 17px;
    margin: auto;
    display: block;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }
  #user-secondary-nav ul.dropdown-menu li a {
    color: black;
  }
}
/**====================usernav-premiumplus.blade.php=============================**/
/**====================client-nav-bar.blade.php=============================**/
#client-selector {
  z-index: 999;
}
p#clients {
  margin-top: 10%;
}
@media only screen and (max-width: 750px) {
  #client-list,
  #client-chosen {
    display: none;
  }
}
#showChosenClient {
  position: fixed;
  bottom: 15px;
  z-index: 1000;
}
.drop-up {
  top: auto;
  bottom: 100% !important;
  overflow: scroll;
  height: 300px;
}
.clients-email {
  font-weight: normal;
  font-size: 12px;
}
#coach-client-list {
  padding-right: 0;
}
/**====================client-nav-bar.blade.php=============================**/
/**====================footer.blade.php=============================**/
#footer li a {
  font-size: 12px !important;
}
.footer-text {
  color: white !important;
}
#footer {
  white-space: nowrap;
}
#footer-more {
  margin-right: 5%;
}
#support {
  margin-right: 5%;
}
#tablet {
  display: none;
}
#phone {
  display: none;
}
#job-search-help {
  margin-right: 10%;
}
@media only screen and (max-width: 1200px) {
  #job-search-help {
    margin-right: 15% !important;
  }
  #jobscan_footer {
    margin-left: 0% !important;
  }
}
@media only screen and (max-width: 990px) {
  #job-search-help {
    margin-right: 0% !important;
  }
  #jobscan_footer {
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
  #support {
    margin-top: 3%;
    margin-right: 0;
  }
  #footer-more {
    margin-top: 3%;
    margin-right: 0;
  }
  #tablet {
    display: block;
  }
  #laptop {
    display: none;
  }
}
@media only screen and (max-width: 766px) {
  #tablet {
    display: none;
  }
  #phone {
    display: block;
  }
}
@media only screen and (max-width: 700px) {
  #jobscan_footer {
    margin-left: 0% !important;
  }
}
@media only screen and (max-width: 500px) {
  #job-search-help {
    margin-right: 0% !important;
  }
  .footer-menu {
    margin-right: 0% !important;
  }
}
@media only screen and (max-width: 400px) {
  .phone-footer {
    padding-left: 0px;
    padding-right: 50px;
  }
  #jobscan_footer,
  #job-search-help,
  #support,
  #footer-more {
    padding-right: 0;
  }
}
#jobscan_footer {
  margin-right: 5%;
  margin-left: 8%;
}
/**====================footer.blade.php=============================**/
/**====================npsForm.blade.php============================**/
.tf-v1-popover.open {
  bottom: 140px;
}
.tf-v1-popover .tf-v1-popover-button {
  bottom: 70px;
}
.tf-v1-popover .tf-v1-popover-tooltip {
  bottom: 77px;
}
